/*
|--------------------------------------------------------------------------
| Sliders
|--------------------------------------------------------------------------
|
| Création de sliders.
*/
import $ from 'jquery'

export const slickHomeSlider = (root = document) => {
  $(root).find('.js-home-slider').slick({
    dots: false,
    arrows: true,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendArrows: '.js-home-slider-nav',
    prevArrow: '.js-home-slider-prev',
    nextArrow: '.js-home-slider-next',
  })
}

export const slickNewsSlider = (root = document) => {

  $(root).find('.js-news-slider-image').slick({
    dots: false,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.js-news-slider-text'
  })

  $(root).find('.js-news-slider-text').slick({
    dots: false,
    arrows: true,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendArrows: '.js-news-slider-nav',
    prevArrow: '.js-news-slider-prev',
    nextArrow: '.js-news-slider-next',
    asNavFor: '.js-news-slider-image'
  })

}



// Fonction permettant de détruire un carousel Slick
export function destroySlick(root = document, delay = 490) {
  setTimeout(() => {
    $(root).find('.slick-initialized').slick('unslick')
  }, delay)
}
