/*
|--------------------------------------------------------------------------
| Fonctions
|--------------------------------------------------------------------------
|
| Les divers scripts du site.
*/

import $ from 'jquery'
import anime from 'animejs/lib/anime.es.js'
import { OBSERVER } from './../main.js'
import { getElementOffset, isVisible } from './helper.js'


// Ajouter / Enlever la class pour faire afficher le bouton des liens rapides en mobile
export function closeAlerts(root = document) {
  OBSERVER.add({ name: 'closeAlertsBtn', event: 'click', function: closeAlerts, target: '.js-alerts-close', root: root })
  OBSERVER.on('closeAlertsBtn')

  function closeAlerts() { 
    document.querySelector('html').classList.remove('showAlerts')
  }
}

export function animButtons() {

  //mettre un span à l'intérieur des liens
  let links = $('.dynamic a, .btn, .js-hover-btn')
  links.wrapInner('<span></span>');

  //style de bouton secondaire (ajout de la class .btn)
  let secButtons = $('.btn--secondary')
  secButtons.addClass('btn')

  
  let buttons = $('.btn, .js-hover-btn')
  
  buttons.append('<span class=\'hover-anim\'></span>');

  // Animation In/Out
  buttons.on('mouseenter mouseout', function(e) {
    var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
    $(this).find('.hover-anim').css({top:relY, left:relX})
  })
}


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    root: options.root
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    root: options.root,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? options.root.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element, root: options.root }).top + options.offset })
  
  return animation.finished
}


export const viewportWidthWithoutScrollbar = () => {
  //OBSERVER.add('viewportWidthWithoutScrollbar', 'resize', onResize)

  OBSERVER.add({ name: 'viewportWidthWithoutScrollbar', event: 'resize', function: onResize })

  OBSERVER.on('viewportWidthWithoutScrollbar')

  onResize()

  function onResize() {
    let vw = document.documentElement.clientWidth || document.body.clientWidth
    document.documentElement.style.setProperty('--vw', `${vw * 0.01}px`)
  }
}

// Ajouter / Enlever la class pour faire afficher le bouton des liens rapides en mobile
export function fastLinksBtn() {

    OBSERVER.add({ name: 'fastLinksBtn', event: 'scroll', function: onScroll })
    OBSERVER.on('fastLinksBtn')

    onScroll()

    function onScroll() { 
      if (isVisible('.js-footer'))
        $('html').addClass('footer-visible')
      else
        $('html').removeClass('footer-visible')
    }

}


// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins(){
  $( '.dynamic p > a.btn' ).each(function() {
    $(this).parent().addClass('buttonMargin')
  })
}


export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}
function fallbackCopyTextToClipboard(text) {
  var pos = $(document).scrollTop()

  var textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


// Fonction permettant d'activer le calendrier
export const calendar = (root = document) => {

  if (!root.querySelector('#calendarOptions')) //s'il n'y a pas d'events
    return

  let eventsDatesList = root.querySelector('#calendarOptions').dataset.list
  let currentDate = root.querySelector('#calendarOptions').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  var datesEnabled = eventsDatesList.split(',')
  
  $(root).find('.js-calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    var theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ barba.go('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $(root).find('.js-calendar').datepicker('update', currentDate)
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.toLowerCase()

  return result
}


// Initialiser la hauteur de la bannière de l'accueil
export function initHomeBannerHeight() {
  adjustBannerHeight()
  $(window).resize( function() {
    adjustBannerHeight()
  })
}

function adjustBannerHeight(){
  var bannerHght
  var windowHght = $(window).height()
  var headerHght = $('.js-header').height()
  var alertsHght = $('.js-alerts').height()

  bannerHght = windowHght - headerHght

  if ($('html').hasClass('showAlerts'))
    bannerHght = windowHght - headerHght - alertsHght
  else
    bannerHght = windowHght - headerHght


  $('.home-slider__slides__slide').css('height', bannerHght + 'px')
}

// Ajuste les h1 selon le nombre de charactères
export function sizeH1() {
  let i
  let h1 = document.querySelectorAll('h1, .h1')
  let h1Length = h1.length

  for(i=0; i<h1Length; i++) {
    if(h1[i].textContent.length > 35) {
      h1[i].classList.add('smaller')
    }
  }
}