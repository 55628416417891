/*
|--------------------------------------------------------------------------
| Les vues
|--------------------------------------------------------------------------
|
| Attribution des scripts à éxécuter sur chaque page selon leurs vues
*/

// Importation ----------------------------------------------------------------------------------------------------
//import Overlay from './classes/overlay/overlay.min.js'
import Overlay from '../libraries/overlay.js'
import { OBSERVER } from './main.js'
import { fastLinksBtn, animButtons, adjustButtonsMargins, clickToScrollToBlock, calendar, closeAlerts, slugifyProvider, initHomeBannerHeight, sizeH1 } from './functions/functions.js'
import { slickHomeSlider, slickNewsSlider } from './functions/sliders.js'
import { forceBlankOnExterneAndPdfLinks, removeHoverOnMobile } from './functions/helper.js'
import { overlayQuickLinks, overlaySearch, overlayDropdown, overlayMenu, overlayLinks, overlayShare, overlayAlert, overlayCalendar } from './functions/overlays.js'
import { inputsAndTextareaLabel, clearInput, fileUpload, select, textareaHeight, formEmploi, formContact, formSearch, formInfolettre } from './functions/form'
import { Accordions } from './functions/accordions.js'

window.slugifyProvider = slugifyProvider

// Initialisation sur toutes les pages
export class View {
  static init(next) {
    // global init
    forceBlankOnExterneAndPdfLinks()
    closeAlerts(next.container)
    removeHoverOnMobile()

    overlayQuickLinks(next.container)
    overlaySearch(next.container)
    overlayDropdown(next.container)
    overlayMenu(next.container)
    overlayLinks(next.container)
    overlayShare(next.container)
    overlayAlert(next.container)
    inputsAndTextareaLabel()
    clearInput()
    fastLinksBtn()
    animButtons()
    sizeH1()

    clickToScrollToBlock({ selector: '.js-back-to-top' })

    formSearch('#formSearch', '#submitSearch')
    formInfolettre('#footer_newsletter', '#footer_newsletter_submit')
  }

  static leave(current) {
    // global leave
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
  }
}

// Classe initialisant les scripts présents dans les page génériques
export class Generic extends View {
  static initGeneric(next) {
    this.init(next)
    adjustButtonsMargins()
    textareaHeight()
    fileUpload()
    select()
    new Accordions({ container: '.js-rubrics', accordion: '.js-rubric' })
  }

  static leaveGeneric(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome(next) {
    this.init(next)
    slickHomeSlider()
    slickNewsSlider()
    clickToScrollToBlock({ selector: '.js-down-arrow', scrollTo: '.js-under-slider' })
    initHomeBannerHeight()
  }

  static leaveHome(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans la page de liste d'événements
export class Events extends View {
  static initEvents(next) {
    this.init(next)
    select()
    calendar(next.container)
    overlayCalendar()
  }

  static leaveEvents(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans la page de détail d'événement
export class Event extends View {
  static initEvent(next) {
    this.init(next)
  }

  static leaveEvent(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans la page de liste d'événements
export class News extends View {
  static initNews(next) {
    this.init(next)
    select()
  }

  static leaveNews(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans la page de détail d'événements
export class NewsDetail extends View {
  static initNewsDetail(next) {
    this.init(next)
  }

  static leaveNewsDetail(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans la page de liste d'emplois
export class Jobs extends View {
  static initJobs(next) {
    this.init(next)
    select()
  }

  static initFormJobs() {
    formEmploi()
    fileUpload()
  }

  static leaveJobs(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans la page de détail d'emplois
export class Job extends View {
  static initJob(next) {
    this.init(next)
  }

  static initFormJob() {
    formEmploi()
    fileUpload()
  }

  static leaveJob(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans la page de résultat de recherche
export class Search extends View {
  static initSearch(next) {
    this.init(next)
    select()
  }

  static leaveSearch(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans la page de contact
export class Contact extends View {
  static initContact(next) {
    this.init(next)
    textareaHeight()
    new Accordions({ container: '.js-rubrics', accordion: '.js-rubric' })
    formContact()
  }

  static leaveContact(current) {
    this.leave(current)
  }
}

// Classe initialisant les scripts présents dans les pages d'erreur
export class Error extends View {
  static initError(next) {
    this.init(next)
  }

  static leaveError(current) {
    this.leave(current)
  }
}
