/*
|--------------------------------------------------------------------------
| Formulaires et validation
|--------------------------------------------------------------------------
|
| Validation, messages d'erreur, formatage de champs et divers à propos des
| formulaires, c'est ici !
*/

import { OBSERVER } from '../main'
import { formatBytes, isMobile } from './helper'
import $ from 'jquery'

// Ajoute un attribut en considérant si le input est plein ou vide
export const inputsAndTextareaLabel = (root = document) => {
  const elementsString = 'input[type="text"], input[type="email"], textarea',
    formElements = root.querySelectorAll(elementsString),
    formElementsLength = formElements.length
  let i

  for (i = 0; i < formElementsLength; i++)
    formElements[i].parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value

  const setValue = (e) => e.target.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    root: root,
    function: setValue
  })

  OBSERVER.on('inputsAndTextareaLabel')
}


// Permet de supprimer le contenu d'un input
export const clearInput = (root = document) => {
  const onClick = (e) => {
    let input = e.currentTarget.parentNode.querySelector('.js-input-to-clear')
    input.value = ''
    input.focus()
    input.parentElement.dataset.inputvalue = ''
    input.classList.remove('valid')
  }

  OBSERVER.add({
    name: 'clearInput',
    event: 'click',
    function: onClick,
    target: '.js-clear-input',
    root: root
  })

  OBSERVER.on('clearInput')
}


// Modification de la hauteur d'un textarea selon son contenu
export const textareaHeight = (root = document) => {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 32 ? 32 : e.currentTarget.scrollHeight}px`
  }

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: 'textarea',
    root: root,
    function: onInput
  })

  OBSERVER.on('textareaHeight')
}


// Permet de changer le label des input files
export const fileUpload = (root = document) => {
  const clear = (i, element, currentLabelText) => {
    element.value = ''
    element.nextElementSibling.querySelector('.field__text').innerText = currentLabelText
    element.parentNode.dataset['file'] = ''
    OBSERVER.off(`clear${i}`)
  }

  const changeLabel = (e) => {
    const self = e
    const label = e.currentTarget.nextElementSibling.querySelector('.field__text')
    const currentLabelText = label.innerText
    let i, newLabel = '', fileLength = e.currentTarget.files.length

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i=0; i<fileLength; i++) {
          let file = e.currentTarget.files[i]
          newLabel += `${(i+1)}. `

          if ('name' in file) newLabel += `fichier: ${file.name}, `
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`

          const onClear = () => clear(i, self.target, currentLabelText)

          OBSERVER.add({
            name: `clear${i}`,
            event: 'click',
            target: e.currentTarget.previousElementSibling,
            root: root,
            function: onClear
          })

          OBSERVER.on(`clear${i}`)
        }
        e.currentTarget.parentNode.dataset['file'] = newLabel
        label.innerText = newLabel
      }
    }
  }

  OBSERVER.add({
    name: 'fileUpload',
    event: 'change',
    target: 'input[type=file]',
    root: root,
    function: changeLabel
  })

  OBSERVER.on('fileUpload')
}


// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export const select = () => {
  if (!isMobile())
    tail.select('select', { animate: false })
}

// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids())
        // eslint-disable-next-line semi
        return;

      const nbProjectError = validator.errorList.find(item => {
        return item.element.name === 'nbProject'
      })

      if(nbProjectError === undefined) {
        $('html, body').animate({
          scrollTop: $(validator.errorList[0].element).offset().top - 50
        }, 500)
        $('.ajax-disabled').removeClass('ajax-disabled')
      }
    },
    rules: rules,
    messages: messages,
    errorPlacement: function (error, element) {
      error.appendTo(element.parent().parent().find('p.field__error'))
    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function () {
    $(form).submit()
  })
}

// Validation du formulaire d'emploi'
export function formEmploi() {
  validationEmail()
  formatPhone('#f_job_phone')

  var m = getMessages()
  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 5MB')

  var rules = {
    firstname:     { required: true },
    lastname:      { required: true },
    email:         { required: true, courriel: true },
    phone:         { minlength: 12 },
    cv:            { accept: 'pdf', filesize: 5242880, required: true },
    motivation:    { accept: 'pdf', filesize: 5242880 },
  }
  var messages = {
    firstname:     { required: m.required },
    lastname:      { required: m.required },
    email:         { required: m.required, courriel: m.email },
    phone:         { minlength: m.phone },
    cv:            { accept: m.accept, required: m.required },
    motivation:    { accept: m.accept },
  }

  formValidation('#f_job', '#f_job_submit', rules, messages)
}

export function validationEmail() {
  $.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

export function formatPhone(element) {
  $(element).mask('000 000-0000')
}

// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}

// Validation du formulaire de contact'
export function formContact() {
  validationEmail()
  formatPhone('#form-contact-phone')

  var m = getMessages()

  var rules = {
    firstname:     { required: true },
    lastname:      { required: true },
    email:         { required: true, courriel: true },
    phone:         { minlength: 12 },
    message:       { required: true }
  }
  var messages = {
    firstname:     { required: m.required },
    lastname:      { required: m.required },
    email:         { required: m.required, courriel: m.email },
    phone:         { minlength: m.phone },
    message:       { required: m.required }
  }

  formValidation('#form-contact', '#form-contact-submit', rules, messages)
}

// Validation de la recherche'
export function formSearch(form, submit) {

  var m = getMessages()

  var rules = {
    q:     { required: true },
  }
  var messages = {
    q:     { required: m.required },
  }
  formValidation(form, submit, rules, messages)
}

// Validation du formulaire de l'infolettre
export function formInfolettre(formID,formSubmit) {
  validationEmail()
  var m = getMessages()
  var rules = {
    courriel: { required: true, courriel: true, email: false },
  }
  var messages = {
    courriel: { required: m.required, courriel: m.email },
  }

  formValidation(formID, formSubmit, rules, messages)
}
