/*
|--------------------------------------------------------------------------
| Transition avec barba et gestion des vues
|--------------------------------------------------------------------------
|
| C'est ici que les transitions entre les pages sont gérées, on y retrouve
| les différentes vues spécifiques à chaque page. Les vues sont également
| définies ici.
*/

import barba from '@barba/core'
import { View, Generic, Home, Events, Event, News, NewsDetail, Jobs, Job, Search, Contact, Error } from './global.js'
import { defaultTransition } from './transitions/default-transition.js'

window.barba = barba

export const initBarba = () => {

  barba.hooks.enter(({ current }) => {
    current.container.remove()
  })

  barba.init({
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition(),
    ],
    views: [
      {
        namespace: 'stdView',
        beforeEnter({ next }) { View.init(next)  },
        afterLeave ({ current }) { View.leave(current) }
      },
      {
        namespace: 'genericView',
        beforeEnter({ next }) { Generic.initGeneric(next)  },
        afterLeave ({ current }) { Generic.leaveGeneric(current) }
      },
      {
        namespace: 'homeView',
        beforeEnter({ next }) { Home.initHome(next)  },
        afterLeave ({ current }) { Home.leaveHome(current) }
      },
      {
        namespace: 'eventsView',
        beforeEnter({ next }) { Events.initEvents(next)  },
        afterLeave ({ current }) { Events.leaveEvents(current) }
      },
      {
        namespace: 'eventView',
        beforeEnter({ next }) { Event.initEvent(next)  },
        afterLeave ({ current }) { Event.leaveEvent(current) }
      },
      {
        namespace: 'newsView',
        beforeEnter({ next }) { News.initNews(next)  },
        afterLeave ({ current }) { News.leaveNews(current) }
      },
      {
        namespace: 'news-detailsView',
        beforeEnter({ next }) { NewsDetail.initNewsDetail(next)  },
        afterLeave ({ current }) { NewsDetail.leaveNewsDetail(current) }
      },
      {
        namespace: 'jobsView',
        beforeEnter({ next }) { Jobs.initJobs(next)  },
        afterEnter({ next }) { Jobs.initFormJobs(next)  },
        afterLeave ({ current }) { Jobs.leaveJobs(current) }
      },
      {
        namespace: 'jobView',
        beforeEnter({ next }) { Job.initJob(next)  },
        afterEnter({ next }) { Job.initFormJob(next)  },
        afterLeave ({ current }) { Job.leaveJob(current) }
      },
      {
        namespace: 'searchView',
        beforeEnter({ next }) { Search.initSearch(next)  },
        afterLeave ({ current }) { Search.leaveSearch(current) }
      },
      {
        namespace: 'contactView',
        beforeEnter({ next }) { Contact.initContact(next)  },
        afterLeave ({ current }) { Contact.leaveContact(current) }
      },
      {
        namespace: 'errorView',
        beforeEnter({ next }) { Error.initError(next)  },
        afterLeave ({ current }) { Error.leaveError(current) }
      }
    ]
  })
}
