/*
|--------------------------------------------------------------------------
| Popup et sidepanel
|--------------------------------------------------------------------------
|
| Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
*/

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//import Overlay from '../classes/overlay/overlay.min.js'
import Overlay from '../../libraries/overlay.js'
import { OBSERVER } from '../main.js'
import { Accordions } from './accordions.js'
import { copyTextToClipboard } from './functions.js'
//-----------------------------------------------------------------------------------------------------------------

// Fonction gérant les lien rapides
export function overlayQuickLinks() {
  let links = $('.js-quick-links')
  links.hover(
    function() {
      $('html').addClass("showQuickLinks");
    }, function() {
      $('html').removeClass("showQuickLinks");
    }
  )

}

// Fonction gérant le Search
export function overlaySearch(root = document) {
    let search = new Overlay({
      name: 'search',
      events: { open: true, openComplete: true, close: true }, //Standby pour le moment
      create: { close: false },
      click: { buttons: { toggle: '.js-search-btn', switch: '.todo', close: '.closeOverlay' }},
      mouseover: { buttons: { close: '.btnDropdown' }},
      animations: {
        addTransition: true,
        styles: [{ property: 'height', value: '100%', easing: 'easeOutQuart' }]
      },
      options: {
        speed: 800,
        goToSelector: 'html, body',
        root: root,
        closeOnResize: false,  
      }
    })

    function onOpen(){

    }

    function onOpenComplete(){
      // root.querySelector('.overlay-search__container').style.height='auto'
      searchInput.focus()
    }

    function onClose(){
      // let overlayHeight = root.querySelector('.overlay-search__container').clientHeight
      // root.querySelector('.overlay-search__container').style.height=`${overlayHeight}px`
    }

    search.init()
    const searchInput = root.querySelector('.js-search-input')

    OBSERVER.add({ name:'overlaySearch', event: 'onOpenOverlaySearch', function: onOpen })
    OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onOpenComplete })
    OBSERVER.add({ name:'overlaySearch', event: 'onCloseOverlaySearch', function: onClose })
    OBSERVER.on('overlaySearch')
  }

// Fonction gérant l'overlay du dropdown
export function overlayDropdown(root = document) {
  let dropdown = new Overlay({
    name: 'dropdown',
    create: { close: false },
    click: { buttons: { close: '.closeDropdown' }},
    mouseover: {
      delay: 200,
      buttons: { trigger: '.js-btn-dropdown', close: '.js-search-btn, .js-alerts' }
    },
    options: {
      speed: 900,
      goToSelector: 'html, body',
      root: root
    }
  })

  dropdown.init()
}


// Fonction gérant le menu mobile
export function overlayMenu(root = document) {
  let menu = new Overlay({
    name: 'menu',
    create: { close: false },
    click: {
      buttons: {
        close: '.closeOverlayMenu, header .itemLogo a',
        toggle: '.js-menu-btn',
        switch: '.js-search-btn'
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root,
      closeOnResize: false,
    }
  })

  menu.init()

  new Accordions({ container: '.js-accordion-menu', accordion: '.js-accordion-menu-item' })
}


// Fonction l'overlay des liens rapides
export function overlayLinks(root = document) {
  let links = new Overlay({
    name: 'links',
    create: { close: false },
    click: {
      buttons: {
        close: '.closeOverlayMenu, header .itemLogo a',
        toggle: '.js-fast-links-btn',
        switch: '.js-search-btn, .js-menu-btn'
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root
    }
  })

  links.init()
}


// Fonction gérant l'overlay de partage
export const overlayShare = (root = document) => {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  let share = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share-btn',
        //close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })
  share.init()

  $(root).find('#copyShareBtn').click( function() {
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  $('.rubric__share').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.id)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Municipalité de Sainte-Béatrix&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Municipalité de Sainte-Béatrix&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}


// Fonction gérant l'overlay d'alerte'
export const overlayAlert = (root = document) => {

  if(document.querySelector('html').classList.contains('showAlertsPopup')){ 
    let alert = new Overlay({
      name: 'alert',
      create: {
        close: false,
      },
      click: {
        buttons: {
          close: '.close-overlay',
        }
      },
      timeout: {
        delay: 2000,
      },
      options: {
        speed: 800,
        root: root
      }
    })
    alert.init()
  }
}


// Fonction gérant l'overlay de partage
export function overlayCalendar(root = document) {
  let calendar = new Overlay({
    name: 'calendar',
    create: {
      background: false,
    },
    click: {
      buttons: {
        open: '.js-open-overlay-calendar',
        close: '.js-close-overlay-calendar',
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  calendar.init()
}