import anime from 'animejs/lib/anime.es.js'
import { scrollToBlock } from './functions.js'


export class Accordions {
  constructor(object) {
    this.accordions = object
    this.allIsClosed = true
    this.lastHeight = 0
    this.lastIndex = 0
    this.setDefault()
    
    this.init()
  }
  

  setDefault() {
    if (this.accordions === undefined) this.accordions = {}
    if (this.accordions.container === undefined) this.accordions.container = '.js-accordions'
    if (this.accordions.accordion === undefined) this.accordions.accordion = '.js-accordion'
    if (this.accordions.toggleButton === undefined) this.accordions.toggleButton = '.js-accordion-toggle'
    if (this.accordions.content === undefined) this.accordions.content = '.js-accordion-content'
    if (this.accordions.openedClass === undefined) this.accordions.openedClass = 'js-accordion-opened'
    if (this.accordions.closeAllDuration === undefined) this.accordions.closeAllDuration = 300
    if (this.accordions.root === undefined) this.accordions.root = document
    if (this.accordions.closeAllBeforeOpen === undefined) this.accordions.closeAllBeforeOpen = true
    if (this.accordions.scrollToAccordion === undefined) this.accordions.scrollToAccordion = true
  }


  init() {
    this.addIndexInHTML()
    this.hash()

    
    let i
    this.toggleButtons = []
    if (this.accordions.toggleButton !== undefined) {
      this.toggleButtons = Array.prototype.slice.call(this.accordions.root.querySelectorAll(`${this.accordions.container} ${this.accordions.accordion} ${this.accordions.toggleButton}`))
      let toggleButtonsLength = this.toggleButtons.length
      for (i=0; i<toggleButtonsLength; i++) {
        this['boundToggle' + i] = e => this.toggle(e, i)
        this.toggleButtons[i].addEventListener('click', this['boundToggle' + i], false)
      }
    }

    this.boundHash = e => this.hash(e)
    window.addEventListener('hashchange', this.boundHash, false)
  }


  toggle(e) {
    const currentElementIsOpened = e.currentTarget.closest(this.accordions.accordion).classList.contains(this.accordions.openedClass)

    if (!currentElementIsOpened) {
      if (!this.allIsClosed && this.accordions.closeAllBeforeOpen)
        this.closeAll()
      this.open(e.currentTarget.closest(this.accordions.accordion))
    } else
      this.close(e.currentTarget.closest(this.accordions.accordion))
  }


  hash() {
    let hash, accordion
    if (window.location.hash) {
      hash = window.location.hash.substring(1)
      accordion = document.getElementById(`accordion-${hash}`)
     
      if (accordion != null) {
        if (!this.allIsClosed && this.accordions.closeAllBeforeOpen)
          this.closeAll()
        this.open(accordion)
      }
    }
  }


  open(accordion) {
    let i,
      currentContent,
      childrenHeight = 0
    const containers = this.accordions.root.querySelectorAll(this.accordions.container),
      containersLength = containers.length

    for (i=0; i<containersLength; i++) containers[i].style.pointerEvents = 'none' 
    
    accordion.classList.add(this.accordions.openedClass)
    
    currentContent = this.findAccordionContent(accordion)
    const currentContentChildrenLength = currentContent.children.length
    for (i=0; i<currentContentChildrenLength; i++) 
      childrenHeight += currentContent.children[i].clientHeight

    anime({
      targets: currentContent,
      height: childrenHeight,
      easing: 'easeInOutQuart',
      duration: 700,
      complete: () => {
        for (i=0; i<containersLength; i++) containers[i].style.pointerEvents = '' 
        currentContent.style.height = '100%'
      }
    })

    if (this.accordions.scrollToAccordion)
      scrollToBlock({
        scrollTo: accordion,
        duration: 700,
        offset: this.lastIndex >= accordion.dataset.index ? 0 : -this.lastHeight
      })

    this.allIsClosed = false
    this.lastHeight = childrenHeight
    this.lastIndex = accordion.dataset.index
  }


  close(accordion) {
    let i,
      currentContent,
      childrenHeight = 0

    const containers = this.accordions.root.querySelectorAll(this.accordions.container),
      containersLength = containers.length

    for (i=0; i<containersLength; i++) containers[i].style.pointerEvents = 'none' 
    
    accordion.classList.remove(this.accordions.openedClass)
    
    currentContent = this.findAccordionContent(accordion)
    
    
    const currentContentChildrenLength = currentContent.children.length
    for (i=0; i<currentContentChildrenLength; i++) 
      childrenHeight += currentContent.children[i].clientHeight

    currentContent.style.height = `${childrenHeight}px`

    anime({
      targets: currentContent,
      height: '0px',
      easing: 'easeInOutQuart',
      duration: 700,
      complete: () => {
        for (i=0; i<containersLength; i++) containers[i].style.pointerEvents = ''
      }
    })

    this.allIsClosed = true
    this.lastHeight = 0
  }


  closeAll() {
    let i
    const root                = this.accordions.root,
      accordionElements       = root.querySelectorAll(`${this.accordions.container} ${this.accordions.accordion}.${this.accordions.openedClass}`),
      accordionContents       = root.querySelectorAll(`${this.accordions.container} ${this.accordions.accordion}.${this.accordions.openedClass} ${this.accordions.content}`),
      accordionElementsLength = accordionElements.length
    
    for (i=0; i<accordionElementsLength; i++) {
      accordionElements[i].classList.remove(this.accordions.openedClass)
      let j,
        childrenHeight = 0,
        childrenLength = accordionElements[i].querySelector(this.accordions.content).children.length

      for (j=0; j<childrenLength; j++)
        childrenHeight += accordionElements[i].querySelector(this.accordions.content).children[j].clientHeight
      
      accordionContents[i].style.height = `${childrenHeight}px`

      anime({
        targets: accordionContents[i],
        height: '0px',
        easing: 'easeInOutQuart',
        duration: 700
      })
    }

    this.allIsClosed = true
  }


  findAccordionContent(accordion) {
    let i
    const targetChildren = accordion.children,
      targetChildrenLength = targetChildren.length

    for (i=0; i<targetChildrenLength; i++) {
      if (targetChildren[i].classList.contains('js-accordion-content')) {
        return targetChildren[i]
      }
    }
  }


  addIndexInHTML() {
    let i
    const accordions = document.querySelectorAll(`${this.accordions.container} ${this.accordions.accordion}`)
    const accordionsLength = accordions.length

    for (i=0; i<accordionsLength; i++) {
      accordions[i].dataset.index = i
    }
  }


  destroy() {
    let i
    if (this.accordions.toggleButton !== undefined) {
      let toggleButtonsLength = this.toggleButtons.length
      for (i=0; i<toggleButtonsLength; i++)
        this.toggleButtons[i].removeEventListener('click', this['boundToggle' + i], false)
    }

    window.removeEventListener('hashchange', this.boundHash, false)
  }
}

