$(window).on('ajaxInvalidField', function(event, fieldElement, fieldName, errorMsg, isFirst) {
  window.UI.ajaxWaiterOff()
});
  
/**
 * Objet statique de mise à jour du UI
 * @type {{}}
 */

window.UI = {}
/**
 * Cette fonction ajoute la classe ajax-disabled sur un élément qui posséde l’attribut data-ajax-waiter
 * Lorsqu’il est cliqué, il est disablé grâce à l’ajout de la classe ajax-disabled afin déviter les doubles ou triples soumissions.
 */
window.UI.ajaxWaiterOn = function(item) {
  let $target = $(item)
  $target.addClass('ajax-disabled')
}

window.UI.ajaxWaiterOff = function(item) {
  if ( item ) {
    let $target = $(item)
    $target.removeClass('ajax-disabled')
  } else {
    $('.ajax-disabled').removeClass('ajax-disabled')
  }
}